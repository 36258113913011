<template>
    <div
        class="preview-container"
        @mouseover="showPlayButtons"
        @mouseleave="hidePlayButtons">
        <div class="preview-ui">
            <div class="top-line">
                <div class="top-progress" />
            </div>
            <div class="top-elements">
                <div class="top-info">
                    <img
                        v-if="pageLogo"
                        class="logo"
                        :src="pageLogo">
                    <div
                        v-else
                        class="placeholder-logo">
                        YOUR
                        LOGO
                    </div>
                    <div class="top-desc">
                        <div class="top-title">
                            {{ pageName }}
                        </div>
                        <div class="top-sponsored">
                            Sponsored
                        </div>
                    </div>
                </div>
                <div class="top-icons">
                    <div class="top-dots">
                        <icon
                            class="top-dot"
                            size="5"
                            color="white"
                            name="circle" />
                        <icon
                            class="top-dot"
                            size="5"
                            color="white"
                            name="circle" />
                        <icon
                            class="top-dot"
                            size="5"
                            color="white"
                            name="circle" />
                    </div>
                    <div class="top-cross">
                        <div />
                        <div />
                    </div>
                </div>
            </div>
            <div
                v-if="squareStyle || longStyle || fillViewStyle"
                class="background-copy">
                <img
                    v-if="assetType === 'IMAGE'"
                    :key="firstAssetUrl"
                    class="preview-image"
                    :src="firstAssetUrl">
                <video
                    v-if="assetType === 'VIDEO'"
                    :key="firstAssetUrl"
                    ref="videoBack"
                    class="preview-video"
                    muted
                    loop>
                    <source
                        :src="firstAssetUrl"
                        type="video/mp4">
                </video>
            </div>
            <div
                class="image-holder"
                :class="{'fill-view-style': fillViewStyle, 'square-style': squareStyle, 'long-style': longStyle}">
                <img
                    v-if="assetType === 'IMAGE'"
                    :key="firstAssetUrl"
                    class="preview-image"
                    :src="firstAssetUrl">
                <div
                    v-if="controlsActive && assetType === 'VIDEO'"
                    class="play-wrapper"
                    @click="playVideo">
                    <div class="play">
                        <icon
                            v-if="!hidePlayIcon"
                            size="40"
                            color="white"
                            name="audio-play" />
                        <div v-else>
                            <div class="previews-pause-bar" />
                            <div class="previews-pause-bar" />
                        </div>
                    </div>
                </div>
                <video
                    v-if="assetType === 'VIDEO'"
                    :key="firstAssetUrl"
                    ref="video"
                    class="preview-video"
                    muted
                    loop>
                    <source
                        :src="firstAssetUrl"
                        type="video/mp4">
                </video>
                <div
                    v-if="squareStyle || fillViewStyle"
                    class="primary-text">
                    <span v-html="truncate(primaryText, 60)" />
                </div>
                <div
                    v-if="showCaption"
                    class="caption-text">
                    <span v-html="truncate(primaryText, 60)" />
                </div>
            </div>

            <div class="bottom-block">
                <div class="bottom-icon">
                    <icon
                        size="20"
                        color="white"
                        name="chevron-down" />
                </div>
                <a
                    :href="ctaLink"
                    target="_blank"
                    class="bottom-visit">
                    {{ ctaText }}
                </a>
                <!-- <div class="description">
                    <span>{{ desc }}</span>
                    <span
                        v-if="!showmore"
                        class="showmore"
                        @click="showmore = true">more</span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapGetters, mapState } from 'vuex';
import { getAssetStyles } from '@/helpers/getAssetStyles';
import {
    ASSET_STYLE_SQUARE,
    ASSET_STYLE_2_BY_3,
    ASSET_STYLE_LANDSCAPE,
    ASSET_STYLE_PORTRAIT
} from '@/components/ad-studio/store/constants';

export default {
    components: {
        Icon
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
    },
    data() {
        return {
            fillViewStyle: false,
            squareStyle: false,
            longStyle: false,
            showCaption: false,
            hidePlayIcon: false,
            controlsActive: true,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        firstAsset() {
            return this.creatives[0]?.assets.find(asset => asset.aspect_ratio === '9:16') ?? this.creatives[0]?.assets?.[0] ?? {};
        },
        firstAssetUrl() {
            return this.firstAsset?.url;
        },
        assetType() {
            return (this.creatives[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.cta_link;
        },
        ctaText() {
            return this.adData?.cta_text || this.creatives?.cta_text;
        },
        primaryText() {
            return this.adData?.primary_text || this.creatives?.primary_text;
        },
    },
    watch: {
        adData() {
            if (this.adData.platform_controls_media_styles) {
                this.initPlatformMediaStyles();
            }
        }
    },
    mounted() {
        if (this.adData.platform_controls_media_styles) {
            this.initPlatformMediaStyles();
        }
    },
    methods: {
        truncate(str, n) {
            if (str) {
                return (str.length > n) ? str.slice(0, n-1) + '... <span class="more">More</span>' : str;
            }
            return '';
        },
        resetStyles() {
            this.fillViewStyle = false;
            this.squareStyle = false;
            this.longStyle = false;
            this.showCaption = false;
        },
        async initPlatformMediaStyles() {
            this.resetStyles();
            const assetStyles = await getAssetStyles(this.firstAssetUrl, this.assetType);
            if (assetStyles.includes(ASSET_STYLE_SQUARE)) this.squareStyle = true;
            if (assetStyles.includes(ASSET_STYLE_2_BY_3)) this.showCaption = true;
            if (assetStyles.includes(ASSET_STYLE_LANDSCAPE)) this.fillViewStyle = true;
            if (assetStyles.includes(ASSET_STYLE_PORTRAIT)) this.longStyle = true;
        },
        playVideo() {
            if (this.hidePlayIcon) {
                this.$refs.video.pause();
                if (this.squareStyle || this.longStyle || this.fillViewStyle) {
                    this.$refs.videoBack.pause();
                }
                this.hidePlayIcon = false;
            } else {
                this.$refs.video.play();
                if (this.squareStyle || this.longStyle || this.fillViewStyle) {
                    this.$refs.videoBack.play();
                }
                this.hidePlayIcon = true;
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.hidePlayIcon) {
                this.controlsActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container {
    position: relative;
    width: 375px;
    height: 667px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    border-radius: 20px;
    overflow: hidden;
    color: $white;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
    // @media only screen and (max-width: 400px) {
    //     width: 320px;
    //     height: 568px;
    // }
}

.preview-ui {
    position: relative;
    flex: 1;
}
.image-holder{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
}
.image-holder.fill-view-style {
    flex-direction: column;
    justify-content: space-around;
    padding: 50px 0;
}
.image-holder.square-style {
    flex-direction: column;
    justify-content: center;
}
.background-copy {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    filter: blur(35px);
    z-index: 0;
    transform: scale(1.11);
    img, video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
.primary-text {
    font-size: 18px;
    font-weight: 600;
    padding: 25px;
    text-shadow: 1px 1px 10px $black;
}
.caption-text {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    padding: 25px;
    font-size: 18px;
    font-weight: 600;
    text-shadow: 1px 1px 10px $black;
}
.preview-image {
    width: 100%;
    display: inline-block;
    height: auto;
}
.preview-video {
    width: 100%;
    height: auto;
}
.top-elements{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    position: relative;
    z-index: 2;
}
.top-dots{
    white-space: nowrap;
}
.top-dot{
    display: inline-block;
    margin-left: 5px;
}
.top-line{
    height: 3px;
    background: #989898;
    border-radius: 15px;
    position: absolute;
    top:15px;
    left:20px;
    width: calc(100% - 40px);
    z-index: 1;
}
.top-progress{
    position: absolute;
    height: 3px;
    background: white;
    border-radius: 15px;
    position: absolute;
    top:0;
    left:0;
    width: 25%;
}
.top-cross{
   width:10px;
    height: 10px;
    margin-left: 10px;
    margin-right: 15px;
    position: relative;
    div{
        position: absolute;
        top:0px;
        left:15px;
        width: 3px;
        height:25px;
        background: white;
        border-radius: 5px;
        &:first-of-type {
            transform: rotate(45deg);
        }
        &:last-of-type {
            transform: rotate(-45deg);
        }
    }
}
.logo{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:2px solid white;
}
.placeholder-logo{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:1px solid white;
    color:white;
    background:black;
    text-align: center;
    font-weight:900;
    font-size: 9px;
    padding-top:8px;
    line-height: 11px;
}
.top-info{
    display: flex;
    align-items: center;
}
.top-desc{
    margin-left:8px;
}
.top-title{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}
.top-sponsored{
    font-size: 12px;
    color: white;
}
.top-icons{
    display: flex;
    align-items: center;
}

.bottom-block{
    position: absolute;
    z-index: 2;
    bottom:0;
    left:0;
    right:0;
    color:white;
    text-align: center;
    padding: 15px;
}
.bottom-icon{
    transform: rotate(180deg);
    margin-bottom:2px;
}
.bottom-visit{
    color:white;
    display: block;
    padding: 10px 30px;
    font-weight: 700;
    border-radius: 25px;
    font-size: 17px;
    margin-bottom: 15px;
    background: #216DDB;
    text-decoration: none;
}
.bottom-desc{
    font-size: 31px;
    font-weight: 700;
    line-height: 32px;
}
.showmore{
    font-weight: 900;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: underline;
}
.play-wrapper{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}
</style>
<style>
.image-holder .primary-text .more,
.image-holder .caption-text .more {
    text-decoration: underline;
}
</style>
